/* eslint-disable */
import {lista, processar, pegaLista, pegaDados, nao_autorizado} from '../../services/GenericAPI'
import {Filtro, Join, Left, Where} from "../../models/filtro/filtro";
import {CartaoSCH, getBaseSCH} from "../../search/CartaoSCH"
import {EventBus} from '../../helpers/event-bus'
import Cartao from '../../models/cartao'

const url = '/cartoes';

const state = {
  all: [],
  cartao: new Cartao(),
  filtro: new Filtro()
};
const getters = {
  listaCartaos: (state) => {
    return state.all !== undefined ? state.all : []
  },
  pegaCartao: (state) => {
    return state.cartao
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
};


const actions = {
  setCartao({commit}, cartao) {
    commit('SET_SISTEMA', cartao)
  },
  searchItem({commit}, search) {
    state.filtro.filters.addFilter('titulo' ,
      "%"+search+"%",
      'ilike'
    )
  },
  async getItem({commit}, id) {
    var filtro = getBaseSCH('cartao_id', id, '=');
    const list = (await pegaLista(url, filtro)).data;
    commit('SET_SISTEMAS', list.data[0]);
    EventBus.$emit('GET_CONCLUIDO', true)
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', CartaoSCH(perPage))
  },

  async setAllCartaos({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      console.log('SET_SISTEMAS:', list);
      commit('SET_SISTEMAS', list);
      onSucesso()
    } catch (error) {
      onErro()
      console.log(error.response.data);
      // this.dispatch('geral/setObjMsgError', error.response.data);

    }

  },
  async processarCartao({commit}, {cartao,acao,onSucesso, onErro}) {
    try {
      let res = await processar(url + (acao === 'remover' ? '/delete' : '/commit') , cartao, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
      this.dispatch('geral/setObjMsgError', error.response.data);
    }
  },

};

const mutations = {
  SET_SISTEMAS(state, cartaos) {
    state.all = cartaos
  },
  SET_SISTEMA(state, cartao) {
    state.cartao = cartao
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

