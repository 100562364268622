/* eslint-disable */
import {mask} from 'vue-the-mask'

export default {
  data() {
    return {
      setPage: false,
      show_list: false,
      itemsPerPage: 120
    }
  },
  directives: {money:  mask},
  methods: {
    getTotais(total, tam){
      return this.validarCampo(total) ? total : tam
    },
    set_por_paginas(itemsPerPage) {
      this.itemsPerPage = itemsPerPage
    },
  }
}
